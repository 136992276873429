<template>
  <v-alert v-if="releasing || !!actions.length" type="info" class="my-8">
    There is a
    <a
      target="_blank"
      rel="noopener noreferrer"
      class="text-primary font-weight-bold"
      href="https://github.com/jouzen/insight-engine/actions"
    >
      GitHub release
    </a>
    in progress, the list will update once its completed...

    <template #append>
      <v-btn text="Refresh" color="primary" @click="updateRollouts()" />
    </template>
  </v-alert>

  <v-row>
    <v-col cols="12">
      <v-sheet>
        <v-data-table
          show-expand
          single-expand
          expand-on-click
          disable-pagination
          hide-default-footer
          no-data-text="No app content OTA rollouts"
          :loading="loading"
          :headers="otaHeaders"
          :expanded="expanded"
          :items="rollouts"
          :items-per-page="100"
          style="width: 100%; max-width: 100%; cursor: pointer"
        >
          <template #item.age="{ item }">
            <span :style="!item.flavor || item.flavor === 'release' ? 'font-weight: bold' : ''">
              {{
                $dayjs(item.created_at)
                  .fromNow(true)
                  .replace('a day', '1 day')
                  .replace('an hour', '1 hour')
                  .replace('a month', '1 month')
              }}
            </span>
          </template>

          <template #item.value.version="{ item }">
            <span :style="!item.flavor || item.flavor === 'release' ? 'font-weight: bold' : ''">
              {{ item.value.version }}
            </span>
          </template>

          <template #item.release="{ item }">
            <span :style="!item.flavor || item.flavor === 'release' ? 'font-weight: bold' : ''">
              {{ item.flavor ? rolloutProjectName(item) : 'New production content release' }}
            </span>
          </template>

          <template #item.creator="{ item }">
            <span :style="!item.flavor || item.flavor === 'release' ? 'font-weight: bold' : ''">
              {{
                (metadata[item.value.version] &&
                  metadata[item.value.version].rollouts &&
                  metadata[item.value.version].rollouts[item.slug] &&
                  metadata[item.value.version].rollouts[item.slug].creator) ||
                (item.info?.actor !== 'unknown' ? item.info?.actor : item.info?.github_actor + ' (GitHub username)') ||
                'No creator info available'
              }}
            </span>
          </template>

          <template #item.status="{ item }">
            <v-chip
              label
              style="text-transform: capitalize; color: white; min-width: 220px; font-weight: bold"
              :color="rolloutStatusColor(item)"
              :class="isOldVersion(item) ? 'lighten-4' : ''"
            >
              {{
                item.filters.includes('label:criteria:ota-content-private-users')
                  ? 'Private group only'
                  : (item.flavor || 'production') + ' app'
              }}
            </v-chip>
          </template>

          <template #expanded-row="{ item }">
            <td class="expanded-row" :colspan="otaHeaders.length">
              <div class="pl-15 pt-4">
                <div class="d-flex flex-column flex-sm-row mt-4 mb-4 mr-4">
                  <div class="ml-3">
                    <div class="caption">RELEASE INFO</div>
                    <br />

                    {{
                      !item.flavor
                        ? 'Production release made from OTA version: ' +
                          (item?.info?.content_version || 'Not available')
                        : 'Contains changes from following projects: ' +
                          (rolloutProjectNames(item).join(',') || 'Not available')
                    }}
                  </div>

                  <v-spacer />

                  <div class="pr-4">
                    <div class="caption mb-6" style="text-align: right">CREATED</div>
                    <b>{{ $dayjs(item.created_at).format('HH:mm DD MMM YYYY') }}</b>
                  </div>
                </div>

                <div class="d-flex flex-column flex-sm-row mt-8 mb-4 mr-4">
                  <v-spacer />

                  <v-btn
                    v-if="item.filters.includes('label:criteria:ota-content-private-users')"
                    color="blue"
                    text="Private group members"
                    prepend-icon="mdi-account-group"
                    :disabled="!isOTAContentAdmin"
                    @click="editMembersDialog = true"
                  />

                  <v-btn
                    v-if="item.state === 'active'"
                    color="red"
                    text="Disable rollout"
                    prepend-icon="mdi-block-helper"
                    :disabled="!isOTAContentAdmin"
                    @click="disableRollout(item)"
                  />

                  <v-btn
                    v-else
                    color="green"
                    text="Enable rollout"
                    prepend-icon="mdi-check-bold"
                    :disabled="!isOTAContentAdmin"
                    @click="enableRollout(item)"
                  />

                  <template v-if="!!item.flavor">
                    <v-btn
                      v-if="item.filters.includes('label:criteria:ota-content-private-users')"
                      color="purple"
                      text="Release to experimental"
                      prepend-icon="mdi-flask-outline"
                      :disabled="!isOTAContentAdmin || releasing || actions.length > 0 || isOldVersion(item)"
                      @click="elevateRolloutToExperimental(item)"
                    />

                    <template
                      v-if="
                        !isOldVersion(item) &&
                        item.flavor === 'experimental' &&
                        !item.filters.includes('label:criteria:ota-content-private-users')
                      "
                    >
                      <v-btn
                        v-if="!releasing && !actions.length"
                        color="green"
                        text="Release to production"
                        prepend-icon="mdi-rocket-launch-outline"
                        :disabled="!isOTAContentAdmin"
                        @click="elevateRolloutToProduction(item)"
                      />

                      <v-btn
                        v-else
                        color="green"
                        target="_blank"
                        text="Confirm in GitHub"
                        rel="noopener noreferrer"
                        prepend-icon="mdi-check-circle-outline"
                        href="https://github.com/jouzen/insight-engine/pulls"
                        :disabled="!isOTAContentAdmin || !!actions.length"
                      />
                    </template>
                  </template>
                </div>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-sheet>
    </v-col>
  </v-row>

  <v-dialog v-if="!!editMembersDialog" width="600" :model-value="true">
    <v-card>
      <v-card-title>Edit private group members</v-card-title>

      <v-card-text>
        <v-text-field
          v-model.trim="userText"
          v-lowercase
          single-line
          prepend-icon="mdi-account"
          label="Email or UUID to add"
          :loading="loading || waiting"
          :hint="
            userUid.includes('@')
              ? 'Press enter to check the email'
              : !!userText && validateUserUid(userText)
                ? 'Press enter to add the uuid'
                : ''
          "
          :rules="[
            (text: string) =>
              text && !text.includes('@')
                ? validateUserUid(text)
                : !text || loading || userUid
                  ? true
                  : 'No account found with given email',
          ]"
          @update:model-value="userUid = userText"
          @keyup.enter="addAccountToLabel()"
        />

        <v-toolbar :color="hasSelectedUsers ? 'primary' : 'secondary'" density="compact">
          <v-toolbar-title v-if="!hasSelectedUsers">Members ({{ pagination?.total || 0 }})</v-toolbar-title>
          <v-toolbar-title v-else style="color: white !important">
            {{ selectedUsers.length }} users selected
          </v-toolbar-title>

          <v-spacer />

          <v-toolbar-items v-if="hasSelectedUsers" color="white">
            <v-btn @click="removeUsersFromLabel()">
              Remove selected

              <template #append>
                <v-icon>mdi-delete</v-icon>
              </template>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-data-table-server
          v-model="selectedUsers"
          item-value="userUid"
          :items-length="pagination.total"
          :search="search"
          :loading="waiting"
          :items="labelUsers"
          :headers="labelHeaders"
          :items-per-page="usersPerPage"
          :items-per-page-options="[25, 50, 100, 1000]"
          :no-data-text="search ? 'No users matching uuid' : 'No users saved for the group'"
          @update:options="loadUsers($event)"
        >
          <template #header.actions="{ selectAll, allSelected }">
            <v-checkbox :model-value="allSelected" @update:model-value="selectAll(!allSelected)" />
          </template>
          <template #item.createdAt="{ item }">
            {{ item.createdAt ? $dayjs(item.createdAt).format('DD MMM YYYY') : 'Just now' }}
          </template>

          <template #item.actions="{ internalItem, isSelected, toggleSelect }">
            <v-checkbox
              :model-value="isSelected(internalItem)"
              @click.stop
              @update:model-value="toggleSelect(internalItem)"
            />
          </template>

          <template #footer.prepend>
            <v-text-field
              v-model.trim="search"
              hide-details="auto"
              class="px-0 mb-4"
              variant="underlined"
              style="width: 380px"
              prepend-icon="mdi-magnify"
              placeholder="Search by email or UUID"
              :error-messages="userSearchInputError"
              @keyup.enter="loadUsers({ page: 1, itemsPerPage: usersPerPage, search: search })"
            />

            <v-spacer />
          </template>
        </v-data-table-server>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text="Close" @click="editMembersDialog = false" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { validate, version } from 'uuid'

  import { Component, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { compare } from 'compare-versions'

  import { Debounce } from '@jouzen/outo-toolkit-vuetify'

  import { labelHeaders, otaHeaders } from '#views/rollouts/constants'

  import { AppStore, LabelsStore, ProjectsStore, RolloutsStore } from '#stores'

  import { LabelUser } from '#types'

  @Component({})
  class ContentRollouts extends Vue {
    public userUid = ''
    public userText = ''
    public search = ''
    public activePage = 1
    public usersPerPage = 1000
    public userSearchInputError: string | null = null

    public expanded: any[] = []
    public selectedUsers: string[] = []

    public editMembersDialog = false

    public readonly otaHeaders = otaHeaders
    public readonly labelHeaders = labelHeaders

    public readonly env = import.meta.env.VITE_APP_ENV

    private readonly appStore = new AppStore()
    private readonly labelsStore = new LabelsStore()
    private readonly projectsStore = new ProjectsStore()
    private readonly rolloutsStore = new RolloutsStore()

    private interval: number | undefined = undefined

    public get loading() {
      return this.rolloutsStore.loading
    }

    public get waiting() {
      return this.labelsStore.waiting
    }

    public get actions() {
      return this.rolloutsStore.actions
    }

    public get project() {
      return this.projectsStore.project
    }

    public get projects() {
      return this.projectsStore.projects
    }

    public get metadata() {
      return this.rolloutsStore.metadata
    }

    public get rollouts() {
      return this.rolloutsStore.rollouts
    }

    public get releasing() {
      return this.rolloutsStore.releasing
    }

    public get labelUsers() {
      return this.labelsStore.users
    }

    public get pagination() {
      return this.labelsStore.pagination
    }

    public get isOTAContentAdmin() {
      return this.appStore.isOTAContentAdmin
    }

    public get hasSelectedUsers() {
      return this.selectedUsers?.length > 0
    }

    @Watch('releasing', { immediate: true })
    protected onReleasingChanged() {
      if (this.interval) {
        window.clearInterval(this.interval)

        this.interval = undefined
      }

      if (this.isOTAContentAdmin) {
        this.rolloutsStore.checkGitHubStatuses()

        this.interval = window.setInterval(this.updateRollouts, (this.releasing ? 1 : 5) * 60 * 1000)
      }
    }

    public mounted() {
      this.rolloutsStore.listOtaRollouts('insight_content')
    }

    public beforeUnmount() {
      if (this.interval) {
        window.clearInterval(this.interval)

        this.interval = undefined
      }
    }

    @Debounce(500)
    public async loadUsers(options: { page: number; itemsPerPage: number; search?: string }) {
      const requestPayload: any = {
        label: 'criteria:ota-content-private-users',
        itemsPerPage: options.itemsPerPage,
        path: '',
        page: options.page,
        search: options?.search || '',
      }

      if (options?.page === 1 && options.itemsPerPage) {
        requestPayload.path = ''
      } else if (options.page > this.activePage) {
        requestPayload.path = this.pagination?.next || ''
      } else if (options.page < this.activePage) {
        requestPayload.path = this.pagination?.prev || ''
      }

      if (!options?.search) {
        this.userSearchInputError = null
      } else if (options?.search?.includes('@')) {
        const uuids = await this.labelsStore.convertEmailsToUids('stage', [this.userText])

        if (uuids?.length) {
          requestPayload.search = uuids[0]
        } else {
          this.userSearchInputError = 'No account found with given email'
          return
        }
      }

      this.activePage = options.page

      await this.labelsStore.listEnvLabelUsers(this.env, requestPayload)
    }

    public updateRollouts() {
      this.rolloutsStore.listOtaRollouts('insight_content')

      if (this.isOTAContentAdmin) {
        this.rolloutsStore.checkGitHubStatuses()
      }
    }

    public enableRollout(rollout: any) {
      this.rolloutsStore.enableOtaRollout('insight_content', rollout.slug)
    }

    public disableRollout(rollout: any) {
      this.rolloutsStore.disableOtaRollout('insight_content', rollout.slug)
    }

    public isOldVersion(rollout: any) {
      return !!this.rollouts
        .filter((r) => r !== rollout)
        .find(
          (r: any) =>
            (compare(r.value.version, rollout.value.version, '>=') && !!rollout.flavor) ||
            (compare(r.value.version, rollout.value.version, '>') && !r.flavor && !rollout.flavor),
        )
    }

    public validateUserUid(uuid: string) {
      return (validate(uuid) && version(uuid) === 4) || 'Not in email or uuid format'
    }

    public rolloutStatusColor(rollout: any) {
      return rollout.filters.includes('label:criteria:ota-content-private-users')
        ? 'pink'
        : !rollout.flavor || rollout.flavor === 'release'
          ? 'blue'
          : rollout.flavor === 'debug'
            ? 'green'
            : 'purple'
    }

    public rolloutProjectName(rollout: any) {
      const projects = this.rolloutProjectNames(rollout)

      return 'Updated content from ' + (projects.length > 1 ? 'multiple projects' : projects[0] || 'unknown source')
    }

    public rolloutProjectNames(rollout: any) {
      const projects = rollout?.info?.waltari_projects?.split(',') || []

      const rollouts = this.metadata[rollout.value.version]?.rollouts || {}

      const projectNames = projects.map((project: string) => this.projects.find((p) => p.id === project)?.name)

      return projectNames.length ? projectNames : [rollouts[rollout.slug]?.project]
    }

    public async addAccountToLabel() {
      if (this.userUid.includes('@')) {
        const uuids = await this.labelsStore.convertEmailsToUids('stage', [this.userText])

        this.userUid = uuids[0] || this.userText
      } else if (
        this.validateUserUid(this.userUid) &&
        !this.labelUsers.find((u: LabelUser) => u.userUid === this.userUid)
      ) {
        await this.labelsStore.addUsersToLabel('stage', {
          label: 'criteria:ota-content-private-users',
          userUids: [this.userUid],
        })

        this.userText = ''
        this.userUid = ''

        this.$forceUpdate()
      }
    }

    public removeUsersFromLabel() {
      this.$confirm('Remove selected users?', `Are you sure you want to remove selected users from the label?`).then(
        (confirmed) => {
          if (confirmed) {
            this.labelsStore.removeUsersFromLabel('stage', 'criteria:ota-content-private-users', this.selectedUsers)

            this.selectedUsers = []
          }
        },
      )
    }

    public elevateRolloutToProduction(rollout: any, _flavors?: string[]) {
      this.$confirm('Elevate this rollout to production?', `${rollout.value.version}`).then(async (confirmed) => {
        this.rolloutsStore.releasing = true

        if (confirmed && this.env === 'release') {
          await this.rolloutsStore.productionOtaRelease({
            slug: rollout.value.slug,
            version: rollout.value.version,
          })
        }
      })
    }

    public elevateRolloutToExperimental(rollout: any, _flavors?: string[]) {
      this.$confirm('Elevate this rollout to experimental?', `${rollout.value.version}`).then(async (confirmed) => {
        this.rolloutsStore.releasing = true

        if (confirmed && this.env === 'release') {
          await this.rolloutsStore.experimentalOtaRollout({
            slug: rollout.value.slug,
            version: rollout.value.version,
          })
        }
      })
    }
  }

  export default toNative(ContentRollouts)
</script>
