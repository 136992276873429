<template>
  <v-row align="center" class="parameters mb-n2">
    <v-col>
      <v-text-field
        hide-details
        label="Name"
        density="compact"
        :single-line="isSingleLine"
        :model-value="editedParameter.name"
        :disabled="isDisabled"
        @update:model-value="editedParameter.name = snakeCase($event.trim())"
      />
    </v-col>

    <v-col class="px-0" style="max-width: 105px">
      <v-select
        hide-details
        return-object
        label="Type"
        density="compact"
        :single-line="isSingleLine"
        :items="parameterTypes"
        :model-value="parameterTypes.find((p) => p.value === type$Case)"
        :disabled="isDisabled"
        @update:model-value="editedParameter.type = cloneDeep($event.type) as any"
      />
    </v-col>

    <v-col>
      <v-text-field
        v-if="type$Case === 'boolean'"
        readonly
        hide-details
        density="compact"
        label="Default value"
        :single-line="isSingleLine"
        :rules="[valueTypeRule]"
        :disabled="isDisabled"
        :model-value="defaultValue"
        @click="defaultValue = !defaultValue"
      />

      <v-text-field
        v-else-if="type$Case === 'integer'"
        hide-details
        persistent-placeholder
        type="number"
        density="compact"
        label="Default value"
        placeholder="Default value"
        :disabled="isDisabled"
        :rules="[valueTypeRule]"
        :single-line="isSingleLine"
        :model-value="defaultValue"
        @update:model-value="defaultValue = $event"
      />

      <v-select
        v-else-if="type$Case === 'enum'"
        hide-details
        density="compact"
        label="Default value"
        no-data-text="No members set"
        :items="values"
        :disabled="isDisabled"
        :single-line="isSingleLine"
        :model-value="defaultValue"
      />

      <v-text-field
        v-else-if="type$Case === 'fixedPoint'"
        hide-details
        density="compact"
        label="Default value"
        :disabled="isDisabled"
        :rules="[valueTypeRule]"
        :single-line="isSingleLine"
        :model-value="editedFixedPoint"
        @update:model-value="defaultValue = $event"
        @blur="editedFixedPoint = defaultValue"
      />

      <v-text-field
        v-else-if="type$Case === 'duration'"
        hide-details
        persistent-placeholder
        type="number"
        density="compact"
        label="Default value"
        placeholder="In seconds"
        :disabled="isDisabled"
        :rules="[valueTypeRule]"
        :single-line="isSingleLine"
        :model-value="defaultValue.seconds ?? null"
        @update:model-value="defaultValue.seconds = $event || '0'"
      />

      <v-text-field
        v-else
        hide-details
        density="compact"
        label="Default value"
        :disabled="isDisabled"
        :rules="[valueTypeRule]"
        :single-line="isSingleLine"
        :model-value="defaultValue"
        @update:model-value="defaultValue = $event"
      />
    </v-col>

    <v-col style="max-width: 24px" class="ml-n2 mr-6 pa-0">
      <v-tooltip v-if="!createdAt || index >= originalParameters.length" text="Delete parameter">
        <template #activator="{ props }">
          <v-btn v-bind="props" icon="mdi-delete" :disabled="isNameField" @click="emitDelete(index)" />
        </template>
      </v-tooltip>

      <v-tooltip v-else :text="isDeprecated ? 'Deprecated parameter' : 'Deprecate parameter'">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            icon="mdi-archive"
            :color="isDeprecated ? 'error' : 'grey'"
            :disabled="isNameField || !originalParameter || isDeprecated"
            @click="editedParameter.deprecated = !isDeprecated"
          />
        </template>
      </v-tooltip>
    </v-col>
  </v-row>

  <v-row v-if="type$Case === 'enum'" class="parameters mt-n4 mb-n2">
    <v-col class="d-flex flex-row align-center justify-center">
      <v-icon icon="mdi-format-list-bulleted" />

      <v-text-field
        v-model.lower="editedEnumMember"
        single-line
        hide-details
        persistent-placeholder
        class="ml-3"
        density="compact"
        label="Add enum members"
        placeholder="Enter enum member to add or remove"
        :disabled="isDeprecated"
        @keyup:enter="addOrRemoveEnumMember()"
        @click:append="addOrRemoveEnumMember()"
      />
    </v-col>

    <v-col style="max-width: 24px" class="ml-n2 mr-6 pa-0">
      <v-tooltip>
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            class="mt-2 mr-n3"
            :icon="`mdi-${values.includes(editedEnumMember) ? 'minus' : 'plus'}`"
            :disabled="!values || isDeprecated"
            @click="addOrRemoveEnumMember()"
          />
        </template>

        <span>{{ values.includes(editedEnumMember) ? 'Remove' : 'Add' }} enum member to the list</span>
      </v-tooltip>
    </v-col>
  </v-row>

  <v-row
    v-if="
      editedParameter.name !== 'enabled' ||
      !editedParameter.description ||
      (originalParameters[index] && !originalParameters[index].description)
    "
    class="parameters mt-n4 mb-n2"
  >
    <v-col class="d-flex flex-row align-center justify-center">
      <v-icon icon="mdi-information-variant" :color="isDeprecated ? 'grey' : ''" />

      <v-text-field
        single-line
        hide-details
        class="ml-3"
        density="compact"
        label="Short description of the purpose *"
        :disabled="isDeprecated"
        :model-value="isDeprecated ? 'Deprecated, should not be used' : editedParameter.description"
        @update:model-value="editedParameter.description = $event"
      />
    </v-col>

    <v-col style="max-width: 24px" class="ml-n2 mr-6 pa-0">
      <v-tooltip text="Toggle parameter tracking in Amplitude">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            class="mt-2 mr-n3"
            icon="mdi-google-analytics"
            :disabled="isDeprecated"
            :color="editedParameter.visibility === AnalyticsVisibility.EXPORTED ? 'primary' : 'grey'"
            @click="toggleAmplitudeVisibility(editedParameter)"
          />
        </template>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script lang="ts">
  import slug from 'slug'

  import { cloneDeep, snakeCase } from 'lodash-es'

  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { Parameter, Parameter_AnalyticsVisibility } from '@jouzen/feature-mgmt-api/parameter'

  import { parameterTypes } from '#views/features/constants'

  import { fixedPointToString, stringToFixedPoint } from '#views/features/utilities'

  @Component({})
  class FeatureParam extends Vue {
    @Prop({ required: true }) public index!: number
    @Prop({ required: true }) public createdAt: string | undefined
    @Prop({ required: true }) public editedParameter!: Parameter
    @Prop({ required: true }) public originalParameters!: Parameter[]

    public editedEnumMember = ''
    // In absence of proper value model on blur update, editedFixedPoint is used to show stringified value of stored fixedPoint.
    public editedFixedPoint = '0.0'

    public readonly slug = slug
    public readonly snakeCase = snakeCase
    public readonly cloneDeep = cloneDeep
    public readonly fixedPointToString = fixedPointToString

    public readonly parameterTypes = parameterTypes

    public readonly AnalyticsVisibility = Parameter_AnalyticsVisibility

    public get isDeprecated() {
      return this.editedParameter.deprecated
    }

    public get isNameField() {
      return this.index === 0
    }

    public get isSingleLine() {
      return this.index > 0
    }

    public get originalParameter() {
      return this.originalParameters[this.index]
    }

    public get type$Case() {
      return this.editedParameter.type?.oneOf?.$case
    }

    public get type() {
      return this.type$Case ? (this.editedParameter.type?.oneOf as any)[this.type$Case] : {}
    }

    public get listValues() {
      return this.values.map((value) => ({ text: value, value }))
    }

    public get values() {
      return this.type?.values || []
    }

    public set values(values: string[]) {
      if (Object.hasOwn(this.type, 'values')) {
        this.type.values = values
      }
    }

    public get defaultValue() {
      const defaultValue = this.type?.defaultValue

      if (this.type$Case === 'fixedPoint') {
        return fixedPointToString(defaultValue)
      }

      return defaultValue
    }

    public set defaultValue(value: any) {
      if (Object.hasOwn(this.type, 'defaultValue')) {
        this.type.defaultValue = this.transformParamValue(value)
      }
    }

    public get valueTypeRule() {
      if (this.type$Case === 'boolean') {
        return (
          this.defaultValue === 'true' ||
          this.defaultValue === true ||
          this.defaultValue === 'false' ||
          this.defaultValue === false ||
          `Not valid ${this.type$Case}`
        )
      }

      if (this.type$Case === 'integer') {
        return !isNaN(this.defaultValue) || `Not valid ${this.type$Case}`
      }

      if (this.type$Case === 'fixedPoint') {
        return stringToFixedPoint(this.defaultValue, 6, true) !== undefined || `Not valid ${this.type$Case}`
      }

      return true
    }

    public get isDisabled() {
      return (this.isNameField || !!this.createdAt || this.isDeprecated) && this.index < this.originalParameters.length
    }

    @Emit('delete')
    public emitDelete(index: number) {
      return index
    }

    public addOrRemoveEnumMember() {
      if (this.values.includes(this.editedEnumMember)) {
        this.values = this.values.filter((value) => value !== this.editedEnumMember)
      } else {
        this.values = [...this.values, this.editedEnumMember]
      }

      if (this.values.length === 0) {
        this.defaultValue = ''
      } else if (this.values.length === 1) {
        this.defaultValue = this.values[0]
      }

      this.editedEnumMember = ''
    }

    public toggleAmplitudeVisibility(parameter: any) {
      parameter.visibility =
        parameter.visibility === Parameter_AnalyticsVisibility.EXPORTED
          ? Parameter_AnalyticsVisibility.HIDDEN
          : Parameter_AnalyticsVisibility.EXPORTED
    }

    private transformParamValue(value: string) {
      if (this.type$Case === 'boolean') {
        return value === 'true' ? true : value === 'false' ? false : value
      }

      if (this.type$Case === 'integer') {
        return !isNaN(parseInt(value)) ? parseInt(value) : 0
      }

      if (this.type$Case === 'fixedPoint') {
        return stringToFixedPoint(value, 6, true)
      }

      return value
    }
  }

  export default toNative(FeatureParam)
</script>
